import React from "react";
import {navigate} from "gatsby";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import AppConfig from '../appconfig';
import RegisterForm from './registerForm';
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import anonUser from '../../static/anon_user.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  setLoggedIn,
  reset,
  addRoomData,
  setAllowDm,
  setBlockedUsernames,
  setAvatarUrl,
  setShowAdModal
} from "../state/actions";
import { FormattedMessage, injectIntl } from 'react-intl';



class AdModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
			passwordInvalid: "",
			usernameInvalid: "",
			createAdFeedback: "", 
			signInFeedback: "",
			isLoading: false
		};

		this.setIsLoading = this.setIsLoading.bind(this);
		this.setPasswordInvalid = this.setPasswordInvalid.bind(this);
		this.setUsernameInvalid = this.setUsernameInvalid.bind(this);
		this.setCreateAdFeedback = this.setCreateAdFeedback.bind(this);
	}
	

 	setIsLoading(value) {
		this.setState({
			isLoading: value
		});
 	}

 	setPasswordInvalid(value) {
		this.setState({
			passwordInvalid: value
		});
 	}

 	setUsernameInvalid(value) {
		this.setState({
			usernameInvalid: value
		});
 	}

 	setCreateAdFeedback(value) {
		this.setState({
			createAdFeedback: value
		});
 	}

 	setSignInFeedback(value) {
		this.setState({
			signInFeedback: value
		});
 	}

 	render() {
 		let name = this.props.name;
 		if (name == undefined || name == '') {
 			name = this.props.username;
 		}

 		let imgSrc = this.props.avatarUrl;
 		if (this.props.avatarUrl == undefined || this.props.avatarUrl == '') {
 			imgSrc = anonUser;
 		}

		return (
			<div className="adModalWrapper">
				<div className={this.props.show ? 'modal-backdrop show' : 'fade'}></div>
				<div className={this.props.show ? 'adModal' : 'hidden'}>
					<div className="adModalInner modal-content">
						<Modal.Header>
								<Modal.Title>
									<div className="modalHeader">
										<span className="modal-user-name">{name}</span> <FormattedMessage id="seeking" /> <span className="modal-header-seeking">
											{this.props.seeking}</span>
									</div>
								</Modal.Title>
								<div className="removeCross modalCross" onClick={() => this.props.onHide()}><FontAwesomeIcon icon={faTimes} /></div>
						</Modal.Header>

						<Modal.Body>
							
							<div className="adModalAvatar">
		                        <img className="adModalAvatarImg rounded-circle" src={imgSrc} alt="Avatar" />
		                    </div>

		                    <div className="modal-description">
		                        <div>
		                            <div>{name}</div>
		                            <div className="adModalUsername" onClick={ () => navigate(this.props.langUrl + '/user/' + this.props.username) }>
		                            	@<span className="modal-username">{this.props.username}</span>
		                            </div>
		                        </div>
		                        <div className="adModalCategory">
		                            <span className="grid-category-am">{this.props.gender}</span> <FormattedMessage id="seeking" /> <span className="grid-category-seeking">
		                            {this.props.seeking}</span>
		                        </div>
		                    </div>

		                    <h4 className="adModalTitle">{this.props.title}</h4>
							<div className="adModalText">{this.props.text}</div>

							<Button size="sm" className="adModalBtn" variant={'info'} onClick={ () => navigate(this.props.langUrl + '/message/' + this.props.username) }>
								<svg className="svg-inline--fa fa-pencil-alt fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas" 
									data-icon="pencil-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
									<path fill="currentColor" d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z">
									</path>
								</svg>
								<span className="adModalWriteTo"><FormattedMessage id="writeTo" /> {name}</span>

							</Button>
						</Modal.Body>
					</div>
				</div>
			</div>
		);
		
	 }
}


function mapStateToProps(state) {
	const { loggedIn, messages } = state
	return { 
		loggedIn: loggedIn,
		globalState: state
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		setLoggedIn: setLoggedIn,
		reset: reset,
		addRoomData: addRoomData,
		setAllowDm: setAllowDm,
		setBlockedUsernames: setBlockedUsernames,
		setAvatarUrl: setAvatarUrl,
		setShowAdModal: setShowAdModal
	},
	dispatch
	);
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AdModal);




Date.prototype.addDays = function(days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}