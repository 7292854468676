import React, { useState, useRef } from "react"
import { navigate } from 'gatsby';
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import AppConfig from '../appconfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons'
import { faComment } from '@fortawesome/free-regular-svg-icons';
import anonUser from '../../static/anon_user.png';
import { FormattedMessage } from 'react-intl';
import Tag from "./tag";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import VoteWrapper from "./voteWrapper";
import {
	setShowSignInModal
} from "../state/actions";

class PostItem extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			postId: this.props.postId,
			title: this.props.title,
			text: this.props.text,
			created: this.props.created,
			type: this.props.type,
			imageUrl: this.props.imageUrl,
			votedUp: this.props.votedUp,
			votedDown: this.props.votedDown,
			score: this.props.score
		};


		this.vote = this.vote.bind(this);
		this.resetVote = this.resetVote.bind(this);
	}

	vote(event, voteType) {
		event.preventDefault();
		event.stopPropagation();

		if (!this.props.loggedIn) {
			//this.props.setShowSignInModal(true);
			//return false;
		}

		let scoreUpdate = 1;
		if (voteType == -1) {
			// The user voted down.
			if (this.state.votedDown != undefined) {
				// Already voted down. Do nothing.
				return false;
			}

			if (this.state.votedUp != undefined) {
				// Already voted up. Reduce the score by 2.
				scoreUpdate = 2;
			}

			this.setState({
				votedUp: undefined,
				votedDown: faThumbsDown,
				score: this.state.score - scoreUpdate
			});
		} else {
			// This user voted up.
			if (this.state.votedUp != undefined) {
				// Already voted up. Do nothing.
				return false;
			}

			if (this.state.votedDown != undefined) {
				// Already voted down. Increase the score by 2.
				scoreUpdate = 2;
			}
			this.setState({
				votedUp: faThumbsUp,
				votedDown: undefined,
				score: this.state.score + scoreUpdate
			});
		}

		let userId = localStorage.getItem('userId');
		let sessionId = localStorage.getItem('sessionId');

		let url = AppConfig.API_URL + 'castPostVote';

		fetch(url, {
			method: 'post',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			body: JSON.stringify({
				userId: userId,
				sessionId: sessionId,
				postId: this.state.postId,
				scoreVal: voteType
			})
		})
			.then(res => res.json())
			.then(
				result => {
					if (result.success) {
						// Do nothing
					} else {
						if (result.errorCode == 6) {
							alert('Det är inte tillåtet att rösta ned under 0');
						} else if (result.errorCode == 101) {
							window.location.reload();
						}

						this.resetVote(voteType);
					}
				},
				error => {
					console.log("Internal error");
				}
			);

		return false;
	}

	resetVote(voteType) {
		if (voteType == -1) {
			// The user voted down.

			this.setState({
				votedUp: undefined,
				votedDown: undefined,
				score: this.state.score + 1
			});
		} else {
			// This user voted up.

			this.setState({
				votedUp: undefined,
				votedDown: undefined,
				score: this.state.score - 1
			});
		}
	}

	render() {
		let arr = this.props.tags;
		let tags = [];

		if (arr != undefined && arr.length > 0) {
			for (let i = 0; i < arr.length; i++) {
				tags.push(
					<Tag key={arr[i]} name={arr[i]} />
				);
			}
		}

		let topic = "Övrigt";

		switch (this.props.topic) {
			case 1:
				topic = "Sjukvård"
				break;
			case 2:
				topic = "Lag & ordning"
				break;
			case 3:
				topic = "Migration"
				break;
			case 4:
				topic = "Utbildning"
				break;
			case 5:
				topic = "Äldreomsorg"
				break;
			case 6:
				topic = "Klimat"
				break;
			case 7:
				topic = "Jobb"
				break;
			case 8:
				topic = "Ekonomi"
				break;
			case 9:
				topic = "Jämlikhet"
				break;
			case 10:
				topic = "Sport"
				break;
			case 11:
				topic = "Nöje"
				break;
		}

		let isImagePost = false;
		let postItemClass = "postItem";
		if (this.state.imageUrl != undefined && this.state.imageUrl != '') {
			isImagePost = true;
			postItemClass = "postItem image";
		}

		return (
			<div className={this.props.show ? postItemClass : 'hidden'} onClick={() => navigate('/post/' + this.props.urlTitle)} >
				<figure className={'topic' + this.props.topic} data-userid="testId" am-data='' seeking-data=''>

					{!isImagePost ?
						null
						:
						<div className="imagePostWrapper">
							<div className="postImage">
								<img src={this.state.imageUrl} alt="post image" />
							</div>
							<div className="imageGradient" />
							<div className="postTextTitle">{this.state.title}</div>
						</div>
					}

					<div className="postContent">

						<VoteWrapper vote={this.vote} votedUp={this.state.votedUp} votedDown={this.state.votedDown} score={this.state.score} />

						<div className="postText">
							{!isImagePost &&
								<div className="postTextTitle">{this.state.title}</div>
							}

							<p className="postTextContent">{this.state.text}</p>
						</div>

					</div>


					<div className="postFooter">
						<Row>
							<Col className="commentCol">
								<div className="commentWrapper">
									<FontAwesomeIcon icon={faComment} />
									<div>{this.props.comments}</div>
								</div>
							</Col>

							<Col className="topicCol">
								<div className="postTopic postDetails">{topic}</div>
							</Col>

							<Col className="tagCol" xs={5}>
								<div className="postTags postDetails">{tags}</div>
							</Col>
						</Row>
					</div>

				</figure>
			</div>
		);
	}

}

function mapStateToProps(state) {
	const { loggedIn } = state
	return {
		loggedIn: loggedIn
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		setShowSignInModal: setShowSignInModal
	},
		dispatch
	);
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PostItem);