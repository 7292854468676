import React from "react"
import { navigate } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVenus, faMars, faGenderless, faCrown, faHeart, faCaretUp, faAdjust, faCircle, faChessQueen, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { faFrownOpen } from '@fortawesome/free-regular-svg-icons';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Col from 'react-bootstrap/Col';
import AppConfig from '../appconfig';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import InfoModal from "./infoModal";
import PostItem from "./postItem";
import AdModal from "./adModal";
import handleViewport from 'react-in-viewport';
import { FormattedMessage, injectIntl } from 'react-intl';
import SEO from "../components/seo";
import Tag from "./tag";
import FloatingLabel from 'react-bootstrap/FloatingLabel';


class BrowseParties extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			showImageLightBox: false,
			imageLightBoxSrc: '',
			errorText: '',
			isLoading: true,
			showMoreLoader: false,
			showInfoModal: false,
			infoModalText: '',
			showStatsChecked: false,
			showSeekingChecked: false,
			fetchedIds: [],
			userPosts: [],
			selectedTags: [],
			availableTags: ['s','m', 'sd', 'c', 'l', 'v', 'kd', 'mp'],
			tagSelecteds: false, 
			tagSelectedm: false,
			tagSelectedsd: false,
			tagSelectedc: false,
			tagSelectedl: false,
			tagSelectedv: false,
			tagSelectedkd: false,
			tagSelectedmp: false,
			showAdModal: false,
			adModalSeeking: '', 
			adModalTitle: '', 
			adModalText: '',
			adModalUsername: '',
			adModalAvatarUrl: '',
			adModalName: '',
			adModalGender: ''
		};

		this.handleImgClick = this.handleImgClick.bind(this);
		this.hideAdModal = this.hideAdModal.bind(this);
		this.showAdModal = this.showAdModal.bind(this);
		this.closeInfoModal = this.closeInfoModal.bind(this);
		this.showInfoModal = this.showInfoModal.bind(this);
		this.getPosts = this.getPosts.bind(this);
		this.setShowMoreLoader = this.setShowMoreLoader.bind(this);		
		this.handleOutsideClick = this.handleOutsideClick.bind(this);
		this.tagSelected = this.tagSelected.bind(this);
		this.tagUnselected = this.tagUnselected.bind(this);
	}

	componentDidMount() {
		this.getPosts();
	}

 	getPosts() {
 		let userId = localStorage.getItem('userId');
		let sessionId = localStorage.getItem('sessionId');

		let url = AppConfig.API_URL + 'getPstsParties';

		fetch(url, {
			method: 'post',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			body: JSON.stringify({
				userId: userId,
				sessionId: sessionId,
				tagsArr: this.state.selectedTags,
				fetchedIds: this.state.fetchedIds,
				daysAgo: 1
			})
		})
		.then(res=>res.json())
		.then(
			result => {
				if (result.success) {
					// Show loader if 30 posts have been fetched, that means there may be more to fetch later.
					this.setState({
						userPosts: this.state.userPosts.concat(result.userPosts),
						isLoading: false,
						showMoreLoader: result.userPosts.length == 30 ? true : false,
						fetchedIds: this.state.fetchedIds.concat(result.ids)
					});
				} else {
					if (result.errorCode == 101) { 
						window.location.reload();
					}
					
					this.setState({
						infoModalText: this.props.intl.formatMessage({ id: 'errorTryLater' }),
						showInfoModal: true,
						isLoading: false
					});
				}
			},
			error => {
				this.setState({
					infoModalText: this.props.intl.formatMessage({ id: 'errorTryLater' }),
					showInfoModal: true,
					isLoading: false
				});
			}
		);

		return false;
 	}

 	tagSelected(name, selected) {
		if (selected) {
			this.tagUnselected(name);
		} else {
			let newAvailableTags = this.state.availableTags.filter(tag => tag != name);
			let newSelectedTags = this.state.selectedTags;
			newSelectedTags.push(name);

			let tagSelected = "tagSelected" + name;

			this.setState({
				availableTags: newAvailableTags,
				selectedTags: newSelectedTags,
				[tagSelected]: true
			}, () => {
					this.setShowMoreLoader(true);
				}
			);
		}
	}

	tagUnselected(name) {
		let newSelectedTags = this.state.selectedTags.filter(tag => tag != name);
		let newAvailableTags = this.state.availableTags;
		newAvailableTags.push(name);

		let tagSelected = "tagSelected" + name;

		this.setState({
			availableTags: newAvailableTags,
			selectedTags: newSelectedTags,
			[tagSelected]: false
		}, () => {
				this.setShowMoreLoader(true);
			}
		);
	}

	handleImgClick(e, data) {
		this.setState({
			imageLightBoxSrc: data,
			showImageLightBox: true
		});
	}


	hideAdModal() {
		document.removeEventListener("click", this.handleOutsideClick, false);
		document.body.classList.remove('modal-open');
		this.setState({
			showAdModal: false,
			adModalSeeking: '', 
			adModalTitle: '', 
			adModalText: '',
			adModalUsername: '',
			adModalAvatarUrl: '',
			adModalName: '',
			adModalGender: ''
		});
	}

	showAdModal(seeking, title, text, username, avatarUrl, name, gender) {
		document.addEventListener("click", this.handleOutsideClick, false);
		document.body.classList.add('modal-open');
		this.setState({
			showAdModal: true,
			adModalSeeking: seeking,
			adModalTitle: title,
			adModalText: text,
			adModalUsername: username,
			adModalAvatarUrl: avatarUrl,
			adModalName: name,
			adModalGender: gender
		});
	}

	handleOutsideClick(e) {
		if (e.target && e.target.className === "adModal") {
			// The wrapper modal class was clicked (outside the popup), close the modal.
			this.hideAdModal();
		}
	};

	showInfoModal(text) {
		this.setState({
			showInfoModal: true,
			infoModalText: text
		});
	}

	setShowMoreLoader(value) {
		this.setState({
			showMoreLoader: value
		});
	}

	closeInfoModal(e) {
		this.setState({
			showInfoModal: false
		});
	}

	onLoadVisible() {
		this.getPosts();
	}


 	render() {
 		let posts = [];
 		let userPosts = this.state.userPosts;

 		if (userPosts != undefined) {
 			for (let i = 0; i < userPosts.length; i++) {
 				let currentPost = userPosts[i];
 				let tags = currentPost.tags;
 				let tagSelected;
 				let show = false;

 				if (tags != undefined) {
	 				for (let i = 0; i < tags.length; i++) {
	 					tagSelected = "tagSelected" + tags[i];
	 					
	 					if (this.state[tagSelected]) {
	 						show = true;
	 						break;
	 					}
	 				}
	 			}

 				posts.push(
 						<PostItem show={show} key={'post'+currentPost.id} postId={currentPost.id} urlTitle={currentPost.urlTitle}
	 						imageUrl={(currentPost.thumbnailUrl == undefined || currentPost.thumbnailUrl == '') ? currentPost.imageUrl : currentPost.thumbnailUrl} 
	 						title={currentPost.title} text={currentPost.text} created={currentPost.created} 
	 						topic={currentPost.topic} type={currentPost.type} tags={currentPost.tags != undefined ? currentPost.tags.slice(0, 2) : undefined} 
	 						votedDown={currentPost.userVoteType == -1 ? faThumbsDown : undefined} votedUp={currentPost.userVoteType == 1 ? faThumbsUp : undefined} 
	 						score={currentPost.score} comments={currentPost.comments} />		
 				);
 			}
 		}

	let availableTags = [];
 		let availableTagsState = this.state.availableTags;
 		if (availableTagsState != undefined && availableTagsState.length > 0) {
 			for (let i = 0; i < availableTagsState.length; i++) {
 				availableTags.push (
 					<Tag key={availableTagsState[i]} name={availableTagsState[i]} tagSelected={this.tagSelected} />
 				);
 			}
 		}

 		let selectedTags = [];
 		let selectedTagsState = this.state.selectedTags;
 		if (selectedTagsState != undefined && selectedTagsState.length > 0) {
 			for (let i = 0; i < selectedTagsState.length; i++) {
 				selectedTags.push (
 					<Tag key={availableTagsState[i]} name={selectedTagsState[i]} tagSelected={this.tagSelected} selected={true} />
 				);
 			}
 		}

    	return (
			<div className="pageWrapper page">
				<SEO 
					title={"Partier"}
					pagePath="/parties"
				/>

				<div className="h-100 chatWrapper">

					<div className="row accountContent">

						<div className="col-xl-6 chat">
								{/*}
								<Spinner className={this.state.isLoading ? 'roomLoader preLoader' : 'hidden'}
									animation="border" size="xl" variant="primary" />
								{*/}
								
								<div className={this.state.isLoading ? 'dcLoader medium' : 'hidden'} />

								{this.state.showImageLightBox &&
									<Lightbox
										mainSrc={this.state.imageLightBoxSrc}
										onCloseRequest={() => this.setState({ showImageLightBox: false })}
									/>
								}

								<div className={this.state.isLoading ? 'card-body msgCardBody visibilityHidden' : 'card-body msgCardBody'}>
									
									<div className="">

										<InfoModal show={this.state.showInfoModal} closeInfoModal={this.closeInfoModal} body={this.state.infoModalText} />
										
										{this.state.errorText == '' && !this.state.isLoading ? 
											<div className="page-content">
												<AdModal show={this.state.showAdModal} onHide={this.hideAdModal}
														seeking={this.state.adModalSeeking}	title={this.state.adModalTitle}	
														text={this.state.adModalText}	username={this.state.adModalUsername}	
														avatarUrl={this.state.adModalAvatarUrl}	name={this.state.adModalName}	
														gender={this.state.adModalGender} langUrl={this.props.langUrl} />

												<div className="filter">
													<div className="searchBar">
														{this.state.selectedTags.length > 0 ? 
															selectedTags
															:
															<span className="tagsLabel">Taggade partier</span>
														}
													</div>

													<div className="availableTags">
														{availableTags}
													</div>
												</div>

												<div className="postItemWrapper">
														 	{posts}

														<ViewportBlock show={this.state.showMoreLoader} onEnterViewport={() => this.onLoadVisible()} />										
												</div>

												{posts.length <= 0 &&
														<div className="errorText">Välj partier ovan för att visa innehåll</div>
												}
											</div>

										 : null
										}

										{this.state.errorText != '' &&  !this.state.isLoading ? 
											<div className="errorText">{this.state.errorText} <br /> <FontAwesomeIcon icon={faFrownOpen} /></div>
										 : null
										}

									</div>
									
								</div>
						</div>

					</div>
				</div>

			</div>
		);
	}
}

function mapStateToProps(state) {
	const { loggedIn, messages } = state
	return { 
		loggedIn: loggedIn,
		globalState: state
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({

	},
	dispatch
	);
}

export default injectIntl(connect(
	mapStateToProps,
	mapDispatchToProps
)(BrowseParties));

const Block = (props, { inViewport: boolean }) => {
  const { inViewport, forwardedRef } = props;
  const color = inViewport ? '#217ac0' : '#ff9800';
  const text = inViewport ? 'In viewport' : 'Not in viewport';
  return (
    <div className={props.show ? 'moreAdsLoader viewport-block' : 'hidden'} ref={forwardedRef}>
		{/*}<Spinner animation="border" size="md" variant="primary" />{*/}
		<div className='dcLoader medium' />
    </div>
  );
};

const ViewportBlock = handleViewport(Block, /** options: {}, config: {} **/);